export const baseURL = "https://api.biztracking.ru"

export const appAPI = {
    async getPlaceInfo(id) {
        try {
            const res = await fetch(`${baseURL}/place/` + id, { headers: { "ngrok-skip-browser-warning": 1 } }).then(res => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async createPlace(formData) {
        try {
            const options = {
                method: "POST",
                body: formData,
            }
            const res = await fetch(`${baseURL}/place`, options).then(res => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async createOrder(formData) {
        try {
            const options = {
                method: "POST",
                body: formData,
            }
            const res = await fetch(`${baseURL}/order`, options).then(res => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
}