import { useEffect, useState } from "react";
import Staff from "./models/Staff";
import toast from "react-hot-toast";
import Result from "./models/Result";
import { useParams } from "react-router-dom";
import { appAPI, baseURL } from "./../../api/service.js";
import Loader from "./../../helpers/loader/loader";
import { VideoUploader } from "@api.video/video-uploader";
import ReactInputMask from "react-input-mask";

function Main() {
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchPlace(id);
    }
  }, [id]);

  const fetchPlace = async (id) => {
    const res = await appAPI.getPlaceInfo(id);
    if (res.success) {
      setPlaceInfo(res.place);
      setMainColor(res.place.color);
    } else {
      toast.error(res.error);
    }
  };

  const [placeInfo, setPlaceInfo] = useState(null);
  const [comment, setComment] = useState("");
  const [image, setImage] = useState(null);
  const [accept, setAccept] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [video, setVideo] = useState(null);

  const [staff, setStaff] = useState(null);

  const [result, setResult] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (staff !== null) deleteInfo();
  }, [staff]);

  const deleteInfo = () => {
    setComment("");
    setImage(null);
    setAccept(false);
    setName("");
    setNumber("");
    setVideo(null);
  };

  const handleSetVideo = async (videoFile) => {
    if (!videoFile) return;
    setImage(null);
    if (!videoFile.type || !videoFile.type.startsWith("video/")) {
      return toast.error("Пожалуйста, выберите файл видео");
    }
    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(videoFile);
    videoElement.preload = "metadata";

    videoElement.onloadedmetadata = () => {
      const duration = videoElement.duration;
      if (duration > 30) {
        toast.error("Видео должно быть не более 30 секунд");
      } else {
        toast.loading("Загружаем видео");
        const fileReader = new FileReader();
        fileReader.onload = () => {
          toast.dismiss();
          setVideo({ file: videoFile });
        };
        fileReader.onprogress = (event) => {};
        fileReader.readAsDataURL(videoFile);
      }
    };
  };

  const handleSetImage = (image) => {
    if (!image) return;
    setVideo(null);
    const fileReader = new FileReader(image);
    fileReader.onload = () => {
      setImage({ img: fileReader.result, file: image });
    };
    fileReader.readAsDataURL(image);
  };

  const handleSubmit = async () => {
    if (
      comment.trim() === "" &&
      (accept ? name.trim() === "" && number.trim() === "" : true)
    )
      return toast.error("Введите все данные");

    try {
      setLoading(true);

      let videoUrl;
      if (video !== null) {
        toast.loading("Загрузка видео может занять время");
        const videoUploader = new VideoUploader({
          file: video.file,
          uploadToken: "to4urX8nOhzJFziD6HIfJ3AD",
          chunkSize: 1024 * 1024 * 10,
          retries: 10,
        });

        try {
          videoUrl = await videoUploader
            .upload()
            .catch((error) => console.log(error.status, error.message));
        } catch (e) {
          toast.dismiss();
          return toast.error("Ошибка при загрузке видео");
          console.log(e);
        }
      }

      const formData = new FormData();
      formData.append("comment", comment);
      formData.append("title", placeInfo.title);
      formData.append("placeId", placeInfo._id);

      if (image && image.file) {
        console.log(image);
        formData.append("file", image.file);
      }

      if (video !== null && videoUrl) {
        console.log(videoUrl);
        formData.append("video", videoUrl.assets.mp4);
      }

      if (accept) {
        formData.append("clientName", name);
        formData.append("clientNumber", number);
      }

      if (staff) {
        formData.append("recipientId", staff.telegramId);
      } else {
        formData.append("recipientId", placeInfo.telegramID);
      }

      const res = await appAPI.createOrder(formData);
      if (res.success) {
        setResult({ tone: res.place.positive });
      } else {
        return toast.error(res.error);
      }
      toast.dismiss();
      setTimeout(() => {
        return toast.success("Благодарим за отзыв");
      }, 1000);
    } catch (e) {
      toast.dismiss();
      console.log(e);
    } finally {
      toast.dismiss();
      setLoading(false);
    }
  };

  const [mainColor, setMainColor] = useState(null);

  if (placeInfo === null) return <Loader />;
  return (
    <div style={{ "--main-color": mainColor ? mainColor : "rgb(255, 0, 124)" }}>
      {staff == null && !result ? (
        <>
          {/*<div className="header">{placeInfo.title}</div*/}
          <div className="main without_back">
            <div className="container">
              {placeInfo.logo && (
                <img src={`${baseURL}/` + placeInfo.logo} className="baner" />
              )}
              <div className="input_question_block">
                <label className="input_question">
                  {/*<div>
                    Текст сообщения
                    <div className="require_question">Обязательное</div>
              </div>*/}
                  <textarea
                    type="text"
                    placeholder={`Напишите свой отзыв\n- Качество обслуживания\n- Состояние магазина\n- Качество продукции`}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </label>
              </div>
              <div className="image_question_block">
                <label className="input_question">
                  {/*<div>Фото или видео</div>*/}
                  <label>
                    <div>
                      <div className="image_picker">
                        <div className="image_picker_info">
                          {image && (
                            <img className="image_select" src={image.img} />
                          )}
                          <div className="text_file_image">
                            {!video && !image
                              ? "Загрузите фото или видео"
                              : video
                              ? video.file.name
                              : image
                              ? image.file.name
                              : ""}
                          </div>
                        </div>
                        <div>
                          {(image || video) && !loading && (
                            <svg
                              onClick={(e) => {
                                e.preventDefault();
                                setImage(null);
                                setVideo(null);
                              }}
                              className="image_picker_close"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="24" height="24" fill="none" />
                              <path
                                d="M7 17L16.8995 7.10051"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7 7.00001L16.8995 16.8995"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                    <input
                      type="file"
                      accept="image/* video/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          if (file.type.includes("image")) {
                            const fileName = file.name.toLowerCase();
                            if (fileName.endsWith(".svg")) {
                              return toast.error(
                                "Выберите фото другого расширения"
                              );
                            } else {
                              handleSetImage(file);
                            }
                          } else if (file.type.includes("video")) {
                            handleSetVideo(file);
                          } else {
                            return toast.error(
                              "Загрузите формат видео или фотографию"
                            );
                          }
                        }
                      }}
                      placeholder="Выберите изображение..."
                      style={{ display: "none" }}
                    />
                  </label>
                </label>
              </div>
              <div className="input_question_block">
                <label className="input_brand">
                <input
                    type="checkbox"
                    className="custom-checkbox"
                    checked={accept}
                    onChange={() => setAccept(!accept)}
                  />
                  <span></span>
                  <div className="brand_question">
                    Хочу чтобы вы со мной связались
                    {/*<br />
                    <div className="require_question">
                      Разрешаю обработку персональных данных
                    </div>*/}
                  </div>
                </label>
              </div>

              {accept && (
                <>
                  <div className="input_question_block">
                    <label className="input_question">
                      {/*<div>
                        Как к вам обращаться
                        <div className="require_question">Обязательное</div>
                      </div>*/}
                      <input
                        type="text"
                        placeholder={`Ваше имя`}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="input_question_block">
                    <label className="input_question">
                      {/*<div>
                        Ваша номер телефона
                        <div className="require_question">Обязательное</div>
                      </div>*/}
                      <ReactInputMask
                        mask="+7 (999) 999-99-99"
                        maskChar="_"
                        type="tel"
                        placeholder={`Номер телефона`}
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </label>
                  </div>
                </>
              )}

              {comment.trim() !== "" &&
              (accept ? name.trim() !== "" && number.trim() !== "" : true) &&
              !loading ? (
                <div className="submit" onClick={() => handleSubmit()}>
                  Отправить
                </div>
              ) : loading ? (
                <div className="submit">Отправляем отзыв...</div>
              ) : (
                <div className="submit notactive">Отправить</div>
              )}

              {placeInfo.employees.filter((e) => e.isManage === true).length >
                0 && (
                <>
                  <div className="hr"></div>

                  <div className="write_staff">Написать лично руководителю</div>
                  <div className="staff">
                    {placeInfo.employees.map((employee) => {
                      if (!employee.isManage) return;
                      return (
                        <div
                          className="person_staf"
                          onClick={() => setStaff(employee)}
                        >
                          <div className="person_staf_info">
                            {employee.image && (
                              <img
                                src={`${baseURL}/` + employee.image}
                                className="person_staf_info_image"
                              />
                            )}

                            <div>
                              <div className="name_staff">
                                {employee.username}
                              </div>
                              <div className="role_staff">{employee.role}</div>
                            </div>
                          </div>
                          <img src="right-arrow.svg" className="arrow" />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : !result && staff ? (
        <Staff setStaff={setStaff} staff={staff} placeInfo={placeInfo} />
      ) : (
        <Result
          setResult={setResult}
          result={result}
          placeInfo={placeInfo}
          setStaff={setStaff}
          deleteInfo={deleteInfo}
        />
      )}
    </div>
  );
}

export default Main;
