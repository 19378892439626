

const Loader = () => {
    return (
        <div className="loader_block">
            <img src="/loader.svg"/>
        </div>
    )
}

export default Loader