import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./pages/main/Main";
import Create from "./pages/create/Create";
import { Toaster } from "react-hot-toast";


function App() {
  
  return (
    <div>
      <Toaster />
      <Routes>
        <Route path="/:id" element={<Main />} />
        <Route path="/create" element={<Create />} />
      </Routes>
    </div>
  );
}

export default App;
