import toast from "react-hot-toast";
import { baseURL } from "../../../api/service";

const Result = ({ setResult, result, placeInfo, setStaff, deleteInfo }) => {
  const handleClose = () => {
    setResult(null);
    setStaff(null);
    deleteInfo();
  };

  const handleStaffWrite = (staff) => {
    setResult(null);
    setStaff(staff);
    deleteInfo();
  };

  return (
    <>
      <div className="header back">
        <img src="/left-arrow.svg" onClick={() => handleClose()} />
        Romantic - обратная связь<div></div>
      </div>
      <div className="main">
        <div className="container">
          <div className="like_review">Благодарим за отзыв</div>
          {result.tone ? (
            <div
              className="hint"
              dangerouslySetInnerHTML={{
                __html: placeInfo.goodText.replace(/(?:\r\n|\r|\n)/g, "<br>"),
              }}
            ></div>
          ) : (
            <div
              className="hint"
              dangerouslySetInnerHTML={{
                __html: placeInfo.badText.replace(/(?:\r\n|\r|\n)/g, "<br>"),
              }}
            ></div>
          )}
          {result.tone && (
            <div className="submit" onClick={() => window.open(placeInfo.gis)}>
              Оставить отзыв в 2ГИС
            </div>
          )}
          {result.tone ? (
            placeInfo.inst && (
              <div
                className="submit add"
                onClick={() => window.open(placeInfo.inst)}
              >
                Подписаться на инстаграм
              </div>
            )
          ) : (
            <div className="submit add" onClick={() => handleClose()}>
              Оставить еще отзыв
            </div>
          )}

          {!result.tone &&
            placeInfo.employees.filter((e) => e.isManage === true).length >
              0 && (
              <>
                <div className="hr"></div>

                <div className="write_staff">Написать лично руководителю</div>
                <div className="staff">
                  {placeInfo.employees.map((employee) => {
                    return (
                      <div
                        className="person_staf"
                        onClick={() => handleStaffWrite(employee)}
                      >
                        <div className="person_staf_info">
                          <img
                            src={`${baseURL}/` + employee.image}
                            className="person_staf_info_image"
                          />
                          <div>
                            <div className="name_staff">
                              {employee.username}
                            </div>
                            <div className="role_staff">{employee.role}</div>
                          </div>
                        </div>
                        <img src="right-arrow.svg" className="arrow" />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default Result;
