import { useState } from "react";
import toast from "react-hot-toast";
import * as uuid from "uuid";
import { appAPI, baseURL } from "./../../api/service.js";

function Create() {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [gis, setGis] = useState("");
  const [insta, setInsta] = useState("");
  const [tgGroopId, setTgGroopId] = useState("");
  const [negative, setNegative] = useState("");
  const [success, setSuccess] = useState("");
  const [color, setColor] = useState("");
  const [accept, setAccept] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSetImage = (image) => {
    if (!image) return;
    const fileReader = new FileReader(image);
    fileReader.onload = () => {
      setImage({ img: fileReader.result, file: image });
    };
    fileReader.readAsDataURL(image);
  };

  const handleSetPhoto = (img) => {
    if (!img) return;
    const fileReader = new FileReader(img);
    fileReader.onload = () => {
      setPhoto({ img: fileReader.result, file: img });
    };
    fileReader.readAsDataURL(img);
  };

  const [fullName, setFullName] = useState("");
  const [position, setPosition] = useState("");
  const [phone, setPhone] = useState("");
  const [telegramNick, setTelegramNick] = useState("");
  const [telegramId, setTelegramId] = useState("");
  const [email, setEmail] = useState("");
  const [isManager, setIsManager] = useState(false);
  const [photo, setPhoto] = useState(null);

  const [people, setPeople] = useState([]);

  const handleSubmitPerson = () => {
    if (
      !fullName ||
      !position ||
      !phone ||
      !telegramId ||
      !photo
    )
      return toast.error("Заполните все поля сотрудника");

    const newPerson = {
      username: fullName,
      role: position,
      phone: phone,
      telegramNick: telegramNick,
      telegramId: telegramId,
      mail: email,
      isManage: isManager,
      image: photo,
      id: uuid.v4(),
    };
    setPeople([...people, newPerson]);

    setFullName("");
    setPosition("");
    setPhone("");
    setTelegramNick("");
    setTelegramId("");
    setEmail("");
    setIsManager(false);
    setPhoto(null);

    return toast.success("Сотрудник добавлен");
  };

  const handleSubmit = async () => {
    if (
      !title ||
      !image ||
      !color ||
      !gis ||
      !negative ||
      !success ||
      !tgGroopId
    )
      return toast.error("Заполните все поля места");
    try {
      setLoading(true);
      const editedPeople = people.map((person, index) => {
        const {
          image: { file, ...restImage },
          ...rest
        } = person;
        const fieldName = `employee${index + 1}:file`;
        return {
          ...rest,
          image: { file: restImage.file },
          [fieldName]: file,
        };
      });

      const data = new FormData();
      data.append("title", title);
      data.append("file", image.file);
      data.append("goodText", success);
      data.append("badText", negative);
      data.append("gis", gis);
      data.append("color", color);
      if(insta) {
        data.append("inst", insta);
      }
      data.append("telegramID", tgGroopId);

      const otherData = JSON.stringify(
        editedPeople.map(({ image, ...rest }) => rest)
      );

      data.append("employees", otherData);

      editedPeople.forEach((person) => {
        Object.entries(person).forEach(([key, value]) => {
          if (key.endsWith(":file")) {
            data.append(key, value);
          }
        });
      });

      const res = await appAPI.createPlace(data);

      if (res.success === true) {
        toast.success("Успешно созданно");
        return navigator.clipboard.writeText(res.place.link).then(() => {
          toast.success("Ссылка на страницу скопирована!");
        })
      } else {
        return toast.error(res.error);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="absolute">
        <div className="header">Создать место</div>
        <div className="main">
          <div className="container">
            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Название QR
                  <div className="require_question">Обязательное</div>
                </div>
                <input
                  type="text"
                  placeholder={`Название QR`}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </label>
            </div>
            <div className="image_question_block">
              <label className="input_question">
                <div>
                  Лого<div className="require_question">Обязательное</div>
                </div>
                <label>
                  <div>
                    <div className="image_picker">
                      <div className="image_picker_info">
                        {image && (
                          <img className="image_select" src={image.img} />
                        )}
                        <div className="text_file_image">
                          {image ? image.file.name : "Выберите изображение..."}
                        </div>
                      </div>
                      <div>
                        {image && (
                          <svg
                            onClick={(e) => {
                              e.preventDefault();
                              setImage(null);
                            }}
                            className="image_picker_close"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="24" height="24" fill="none" />
                            <path
                              d="M7 17L16.8995 7.10051"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7 7.00001L16.8995 16.8995"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleSetImage(e.target.files[0])}
                    placeholder="Выберите изображение..."
                    style={{ display: "none" }}
                  />
                </label>
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Ссылка на 2ГИС
                  <div className="require_question">Обязательное</div>
                </div>
                <input
                  type="text"
                  placeholder={`Ссылка на 2ГИС`}
                  value={gis}
                  onChange={(e) => setGis(e.target.value)}
                />
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Ссылка на инстаграм
                </div>
                <input
                  type="text"
                  placeholder={`Ссылка на инстаграм`}
                  value={insta}
                  onChange={(e) => setInsta(e.target.value)}
                />
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_question">
                <div>
                  ID группы
                  <div className="require_question">Обязательное</div>
                </div>
                <input
                  type="text"
                  placeholder={`ID группы`}
                  value={tgGroopId}
                  onChange={(e) => setTgGroopId(e.target.value)}
                />
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Текст при негативном отзыве
                  <div className="require_question">Обязательное</div>
                </div>
                <textarea
                  placeholder={`Текст`}
                  value={negative}
                  onChange={(e) => setNegative(e.target.value)}
                />
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Текст при позитивном отзыве
                  <div className="require_question">Обязательное</div>
                </div>
                <textarea
                  placeholder={`Текст`}
                  value={success}
                  onChange={(e) => setSuccess(e.target.value)}
                />
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Основной цвет
                  <div className="require_question">Обязательное</div>
                </div>
                <input
                  type="text"
                  placeholder={`Цвет RGB`}
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_brand">
              <input
                  type="checkbox"
                  className="custom-checkbox"
                  checked={accept}
                  onChange={() => setAccept(!accept)}
                />
                <span></span>
                <div className="brand_question">
                  Добавить сотрудников
                  <br />
                  <div className="require_question">
                    Отметьте, если хотите добавить сотрудников
                  </div>
                </div>
                
              </label>
            </div>

            {accept && (
              <>
                <div className="input_question_block">
                  <label className="input_question">
                    <div>
                      ФИО
                      <div className="require_question">Обязательное</div>
                    </div>
                    <input
                      type="text"
                      placeholder={`ФИО`}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </label>
                </div>

                <div className="input_question_block">
                  <label className="input_question">
                    <div>
                      Должность
                      <div className="require_question">Обязательное</div>
                    </div>
                    <input
                      type="text"
                      placeholder={`Должность`}
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                    />
                  </label>
                </div>

                <div className="input_question_block">
                  <label className="input_question">
                    <div>
                      Телефон
                      <div className="require_question">Обязательное</div>
                    </div>
                    <input
                      type="text"
                      placeholder={`Телефон`}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </label>
                </div>

                <div className="input_question_block">
                  <label className="input_question">
                    <div>
                      Телеграмм ник
                    </div>
                    <input
                      type="text"
                      placeholder={`Телеграмм ник`}
                      value={telegramNick}
                      onChange={(e) => setTelegramNick(e.target.value)}
                    />
                  </label>
                </div>

                <div className="input_question_block">
                  <label className="input_question">
                    <div>
                      Телеграмм id
                      <div className="require_question">Обязательное</div>
                    </div>
                    <input
                      type="text"
                      placeholder={`Телеграмм id`}
                      value={telegramId}
                      onChange={(e) => setTelegramId(e.target.value)}
                    />
                  </label>
                </div>

                <div className="input_question_block">
                  <label className="input_question">
                    <div>
                      Почта
                    </div>
                    <input
                      type="text"
                      placeholder={`Почта`}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </label>
                </div>

                <div className="input_question_block">
                  <label className="input_brand">
                    <div className="brand_question">
                      Руководитель
                      <br />
                      <div className="require_question">
                        Отметьте, если человек руководитель
                      </div>
                    </div>
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      checked={isManager}
                      onChange={() => setIsManager(!isManager)}
                    />
                    <span></span>
                  </label>
                </div>

                <div className="image_question_block">
                  <label className="input_question">
                    <div>
                      Фотография
                      <div className="require_question">Обязательное</div>
                    </div>
                    <label>
                      <div>
                        <div className="image_picker">
                          <div className="image_picker_info">
                            {photo && (
                              <img className="image_select" src={photo.img} />
                            )}
                            <div className="text_file_image">
                              {photo
                                ? photo.file.name
                                : "Выберите изображение..."}
                            </div>
                          </div>
                          <div>
                            {photo && (
                              <svg
                                onClick={(e) => {
                                  e.preventDefault();
                                  setPhoto(null);
                                }}
                                className="image_picker_close"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect width="24" height="24" fill="none" />
                                <path
                                  d="M7 17L16.8995 7.10051"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7 7.00001L16.8995 16.8995"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleSetPhoto(e.target.files[0])}
                        placeholder="Выберите изображение..."
                        style={{ display: "none" }}
                      />
                    </label>
                  </label>
                </div>

                <div
                  className="submit add"
                  onClick={() => handleSubmitPerson()}
                >
                  Добавить сотрудника
                </div>
              </>
            )}

            {people.length > 0 && (
              <div className="staff">
                {people.map((person) => {
                  return (
                    <div className="person_staf">
                      <div className="person_staf_info">
                        <img
                          src={person.image.img}
                          className="person_staf_info_image"
                        />
                        <div>
                          <div className="name_staff">{person.username}</div>
                          <div className="role_staff">
                            {person.role}{" "}
                            {person.isManage
                              ? "Руководитель"
                              : "Не руководитель"}
                          </div>
                        </div>
                      </div>
                      <img
                        src="/close.svg"
                        className="arrow"
                        onClick={() =>
                          setPeople(
                            people.filter((per) => per.id !== person.id)
                          )
                        }
                      />
                    </div>
                  );
                })}
              </div>
            )}

            {loading ? (
              <div className="submit">Загрузка...</div>
            ) : (
              <div className="submit" onClick={() => handleSubmit()}>
                Создать место
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Create;
